import { Component, OnInit } from '@angular/core';
import { BarcodeScanner, BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { ViewController } from '@ionic/core';
import { BarCodeDetail, ScanCompComponent } from 'src/app/components/scan-comp/scan-comp/scan-comp.component';
import { Booking } from 'src/app/models/booking';
import { MobileEvent, MobileEventAttachment } from 'src/app/models/mobileevent';
import { SynchQueueProvider } from 'src/app/models/syncmanager';
import { BarcodecollectionService } from 'src/app/services/barcodecollection/barcodecollection.service';
import { BookingEventType } from 'src/app/services/booking/booking.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { SyncService } from 'src/app/services/sync/sync.service';
import { UserService } from 'src/app/services/user/user.service';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Capacitor } from '@capacitor/core'

@Component({
  selector: 'app-registerevent',
  templateUrl: './registerevent.page.html',
  styleUrls: ['./registerevent.page.scss'],
})
export class RegisterEventPage implements OnInit {


  ngOnInit() {
  }

  event: MobileEvent;
  eventTypeName: string;
  bookings: Booking[];
  mustScanTspDocNo: boolean;
  disableCancel: boolean; //Disable possibility to leave page
  localBarCodes: string[] = [];
  bcDetail: {};
  eventType: BookingEventType;
  receiverNotPresent: boolean;

  sigName: string;
  canvas: any;
  ctx: any;
  rect: any;
  initialized: boolean;
  resultat: any;
  isDrawing: boolean;
  // x: number;
  // y: number;
  point: pos;
  sigEmpty: boolean = true;
  CANVAS_ELEMENT_ID = "Nisse";
  constructor(
    //public viewCtrl: ViewController,
    public modal: ModalController,
    public navParams: NavParams,
    public sync: SyncService, //Är detta en provider?
    public usr: UserService,
    private camera: Camera,
    private geo: Geolocation,
    //private geolocation: Geolocation,
    private barcodeScanner: BarcodeScanner,
    platform: Platform,
    public settings: SettingsService,
    public scanComp: ScanCompComponent,
    public bcc: BarcodecollectionService
  ) {
    var e = new MobileEvent();
    // e.EventType = navParams.get("eventTypeId");
    // this.eventTypeName = navParams.get("eventTypeName");
    // e.Place = navParams.get("place");
    this.eventType = navParams.get("eventtype");
    this.bookings = navParams.get("bookings");
    e.EventType = this.eventType.eventTypeId;
    this.eventTypeName = this.eventType.name;
    e.Place = this.eventType.place;

    //Not needed
    //e.JobItemId = this.booking.id; //navParams.get('jobItemId')

    // e.EurPallets = this.booking.EurPallets; //navParams.get('EurPallets')
    // e.EurPalletsReturn = this.booking.EurPalletsReturn; //navParams.get('EurPalletsReturn'),

    //Not needed?
    //e.TspDocNo = this.booking.TspDocNo; // navParams.get('TspDocNo')
    this.mustScanTspDocNo = navParams.get("mustScanTspDocNo");
    this.event = e;
    this.localBarCodes = [];

    //TODO:Lägg till detta
    this.geo
      .getCurrentPosition()
      .then(resp => {
        if (resp.coords) {
          this.event.GeoX = resp.coords.latitude;
          this.event.GeoY = resp.coords.longitude;
        }
      })
      .catch(error => {
        console.log("Error getting location", error);
      });

    // platform.registerBackButtonAction(() => {
    //   console.log("Backbutton");
    //   if (this.disableCancel) {
    //     //Do nothing
    //   } else {
    //     this.Cancel();
    //   }
    // });


  }

  ionViewDidLoad() {
    console.log("ionViewDidLoad RegisterEventPage");
  }

  AddPicture(tst) {
    if (tst == "TEST") {
      var idName = Math.round(Math.random() * 100000);
      var imageUri = "assets/imgs/sodexologga.png";
      this.AddAttachment(idName, imageUri);
      return;
    }

    var camOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.FILE_URI,
      sourceType: this.camera.PictureSourceType.CAMERA,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      allowEdit: false,
      correctOrientation: true //Corrects Android orientation quirks
    };

    this.camera.getPicture(camOptions).then(
      imageUri => {
        //alert(imageUri);
        var s = imageUri.split("/");
        var fileName = s[s.length - 1];
        var idName = fileName.split(".")[0]; //Ger ett namn åt filen, 23478234723894234.jpg
        //Vänta med detta
        this.AddAttachment(idName, imageUri);
      },
      message => {
        if (message.indexOf("Camera cancelled.") < 0)
          alert(
            "Tillgång till kameran verkar vara avslagen, vänligen tillåt appen att använda kameran."
          );
      }
    );
  }

  AddAttachment(idName, imageUri) {
    //res.push({ Description: 'Bild', Link: 'ID:' + attachments[i].idName });
    let o: MobileEventAttachment = {
      idName: idName,
      URI: imageUri,
      Description: "Bild",
      Link: "ID:" + idName
    };
    this.event.Attachments.push(o);
  }
  removeImage(ev, img) {
    console.log(img);
    if (confirm("Ta bort bild?")) {
      var idx = this.event.Attachments.findIndex(it => it.idName == img.idName);
      if (idx >= 0) {
        this.event.Attachments.splice(idx, 1);
      }
    }
  }
  GetLocalImagePath(uri: string) {
    return Capacitor.convertFileSrc(uri);
  }
  ChangeTspDocNo() {
    var res = prompt("Ändra fraktsedelsnummer:", this.event.TspDocNo);
    if (res) this.event.TspDocNo = res;
  }
  EditText() {
    var res = prompt("Notering:", this.event.Comment);
    if (res) this.event.Comment = res;
  }
  Save() {
    if (!this.event.BarCodes) this.event.BarCodes = "";

    debugger;
    if (!this.event.Comment) this.event.Comment = '';
    if (this.event.EventType == 25 && this.event.Comment == '') {
      alert('Vid bomkörning måste Notering var ifyllt.');
      return;
    }


    // if (this.event.BarCodes.length == 0) {
    //   if (!confirm("Ingen streckkod är skannad. Vill du fortsätta?")) return;
    // }
    if (!this.event.BarCodes) this.event.BarCodes = ""; //Should not be needed
    //Borde inte behövs hos Sodexo. Borttaget 2019-10-11
    // if (this.event.BarCodes.indexOf(this.booking.TspDocNo) < 0) {
    //   //Add TspDocNo for later identification. Do this on server side instead?
    //   this.event.TspDocNo = this.booking.TspDocNo;
    //   var bcs = this.event.BarCodes.split("\n");
    //   bcs.push(this.booking.TspDocNo);
    //   this.event.BarCodes = bcs.join("\n");
    // }

    //Expand barCodeList
    this.event.BarCodes = this.bcc
      .ExpandBarCodeList(this.event.BarCodes.split("\n"))
      .join("\n");

    var missingScans = [];
    this.bookings.forEach((x) => {
      if (this.event.BarCodes.indexOf(x.TspDocNo) < 0)
        missingScans.push(x);
    });

    if (missingScans.length > 0) {
      if (this.mustScanTspDocNo) {
        var msg = `Du måste skanna följesedeln för att kunna registrera händelsen. Saknar ${missingScans.length} skanningar:\n`;
        msg += missingScans.map((x) => x.TspDocNo).join(' \n');
        alert(msg);
        return;
      }
      //Add barcodes to message anyway to make it possible to identify on server side
      this.event.BarCodes += '\n' + missingScans.map((x) => x.TspDocNo).join(' \n');
    }
    this.event.RegisteredTime = new Date().toISOString();

    if (!this.sigEmpty && (!this.sigName || this.sigName == '')) {
      //alert(this.lang.Get("Reg_Err_MissingName"));
      alert('Namnförtydligande saknas för signatur.');
      return;
    }
    if (this.MustSelectCollis(this.bookings)) {
      if (this.GetSelectedCount() > 0) {
        this.event.ColliMissingIds = [];
        this.GetColliList().filter(x => !x.selected).forEach(x => this.event.ColliMissingIds.push(x.id));
      } else {
        alert('Inga kollin är markerade.');
        return;
      }
    }
    if (this.MustHaveSignature(this.bookings, this.event.EventType)) {
      if (this.receiverNotPresent) {
        if (this.event.Attachments.length == 0) {
          alert('Om mottagaren inte är på plats måste bild läggas till.');
          return;
        }
      } else {
        if (this.sigEmpty) {
          alert('Signatur måste sättas.');
          return;
        }
      }
    }

    //Delivery last confirmation check -specific for Sodexo 
    if (this.event.EventType == 2) {
      if (!confirm("Är du säker på att du vill leveransrapportera?"))
        return;
    }


    //Validation ended

    if (this.receiverNotPresent && this.event.EventType == 2) {
      //Add specific event
      let e2 = JSON.parse(JSON.stringify(this.event));
      e2.EventType = 55;
      this.sync.SaveEventToOutbox("Event", JSON.stringify(e2));
    }

    if (!this.sigEmpty) {
      var sigAtt = new MobileEventAttachment();
      //sigAtt.Description = this.lang.Get("Word_Signature")+":" + this.sigName;
      sigAtt.Description = `Signatur:${this.sigName}`;
      sigAtt.Link = this.GetSignatureLink();
      this.event.Attachments.push(sigAtt);
    }

    var msg = JSON.stringify(this.event);

    this.sync.SaveEventToOutbox("Event", msg);

    //Attachments, do them separately to be able to have separate sync process for images
    this.event.Attachments.map(a => {
      console.log("Adding attachment:");
      console.log(a);
      this.sync.SaveAttachmentToOutBox(a.idName, a.URI);
    });

    //Transfer info to internal booking
    //this.booking.TspDocNo = this.event.TspDocNo;
    // this.booking.EurPallets = this.event.EurPallets;
    // this.booking.EurPalletsReturn = this.event.EurPalletsReturn;

    if (this.eventType.OnAfterEvent)
      this.bookings.forEach((b) => this.eventType.OnAfterEvent(b, this.event));

    let data = { OK: "true", bookings: this.bookings };
    this.modal.dismiss(data);
  }

  Cancel() {
    let data = { OK: "false" };
    this.modal.dismiss(data);
  }

  TrackChange() {
    console.log(this.event.TspDocNo);
  }

  ScanTspDocNo() {
    let scanOptions: BarcodeScannerOptions = {
      //preferFrontCamera: true, // iOS and Android
      //showFlipCameraButton: true, // iOS and Android
      showTorchButton: true, // iOS and Android
      //torchOn: true, // Android, launch with the torch switched on (if available)
      prompt:
        "Sikta på streckkoden. Tryck tillbakapil (vänsterknapp) när du är klar.", // Android
      resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
      //formats: "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
      orientation: "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
      disableAnimations: true, // iOS
      disableSuccessBeep: false // iOS
    };
    this.barcodeScanner.scan(scanOptions).then(
      result => {
        var barCode = result.text;
        if (barCode != "" && !result.cancelled) {
          this.event.TspDocNo = barCode;
        }
        if (result.cancelled) {
          this.disableCancel = true;
          setTimeout(function () {
            this.disableCancel = false;
          }, 1000);
        }
      },
      err => {
        alert("Ett fel uppstod vid skanningen.");
        // An error occurred
      }
    );
  }

  RefreshBarCodeList(barCodeList) {
    barCodeList.forEach(it => {
      if (!this.bcDetail) this.bcDetail = {};
      var bcd = new BarCodeDetail();
      bcd.barcode = it;
      bcd.msg = "(OK)";
      //Is barCode valid?
      var coll = this.bcc.GetCollection(it);
      if (coll) bcd.msg = "Lastb. (" + coll.subCodes.length + "st)";
      //if (!coll && bcd.barcode != this.booking.TspDocNo) {
      if (!coll && this.bookings.findIndex((x) => x.TspDocNo == bcd.barcode) < 0) {
        bcd.msg = "OKÄND"
      }
      this.bcDetail[bcd.barcode] = bcd;
    });
    this.localBarCodes = barCodeList;

    this.localBarCodes = barCodeList;
    this.event.BarCodes = barCodeList.join("\n");
  }

  public GetPresetTexts() {
    //var res = this.usr.GetSetting("PresetTexts", "");
    //alert(res);
    return this.usr.GetSetting("PresetTexts", "").split("|");
  }

  public GetSelectedCount(): Number {
    return this.GetColliList().filter(x => x.selected).length;
  }
  public SetSelectionForAllCollis(sel: Boolean) {
    this.GetColliList().forEach((x) => x.selected = sel);
  }

  public GetColliList() {
    var res = [];
    if (this.bookings)
      this.bookings.forEach(b => {
        if (b.Collis)
          b.Collis.forEach(c => {
            res.push(c);
          })
      });
    return res;
  }
  public MustSelectCollis(b: Booking[]): boolean {
    if (b) {
      var isPickupMoveOrTransport = b.findIndex(x => x.IsPickup() || x.IsMove() || x.IsTransport()) >= 0;
      return (isPickupMoveOrTransport && (this.eventType.key == 'PICKUP' || this.eventType.key == 'LOAD'));
    }
    return false;
  }

  public MustHaveSignature(b: Booking[], eventTypeId: Number): boolean {
    var res = false;
    if (b && eventTypeId == 2) {
      b.forEach(x => {
        if (x.Collis.findIndex(t => t.SignatureNeeded == 1) >= 0)
          res = true;
      });
    }
    return res;
  }
  /* Signature */
  //CANVAS_ELEMENT_ID: string = "Nisse";
  signarr: Array<Array<pos>>;
  currarr: Array<pos>;

  public evCount: number = 0;
  public lastEvent: string = "";

  public SigInit() {
    // if (this.initialized) {
    //   console.log('Init already made.');
    //   return;
    // }

    this.canvas = document.getElementById(this.CANVAS_ELEMENT_ID);
    this.canvas.style.display = "inline";
    this.ctx = this.canvas.getContext("2d");
    this.rect = this.canvas.getBoundingClientRect();
    this.initialized = true;


    this.canvas.addEventListener('mousedown', e => {
      this.evCount++;
      this.lastEvent = 'mousedown';
      this.OnMouseDown(e);
    });

    this.canvas.addEventListener('mousemove', e => {
      this.evCount++;
      this.lastEvent = 'mousemove';
      this.OnMouseMove(e);
    });

    this.canvas.addEventListener('mouseup', e => {
      this.evCount++;
      this.lastEvent = 'mouseup';
      this.OnMouseUp(e);
    });

    //http://bencentra.com/code/2014/12/05/html5-canvas-touch-events.html

    this.canvas.addEventListener("touchstart", e => {
      this.evCount++;
      this.lastEvent = 'touchstart';

      var touch = e.touches[0];
      var mouseEvent = new MouseEvent("mousedown", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      this.canvas.dispatchEvent(mouseEvent);
      //canvas.dispatchEvent(mouseEvent);
    }, false);
    this.canvas.addEventListener("touchend", e => {
      this.evCount++;
      this.lastEvent = 'touchend';

      var mouseEvent = new MouseEvent("mouseup", {});
      this.canvas.dispatchEvent(mouseEvent);
    }, false);
    this.canvas.addEventListener("touchmove", e => {
      this.evCount++;
      this.lastEvent = 'touchmove';

      e.preventDefault(); //Do not move screen
      var touch = e.touches[0];
      var mouseEvent = new MouseEvent("mousemove", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      this.canvas.dispatchEvent(mouseEvent);
    }, false);



    // // Prevent scrolling when touching the canvas
    // document.body.addEventListener("touchstart", function (e) {
    //   if (e.target.id == "Nisse") {
    //     e.preventDefault();
    //   }
    // }, false);
    // document.body.addEventListener("touchend", function (e) {
    //   if (e.target.id == "Nisse") {
    //     e.preventDefault();
    //   }
    // }, false);
    // document.body.addEventListener("touchmove", function (e) {
    //   if (e.target.id == "Nisse") {
    //     e.preventDefault();
    //   }
    // }, false);
  }

  OnMouseDown(e: any) {
    this.currarr = [];
    this.isDrawing = true;

    // this.x = e.clientX - this.rect.left;
    // this.y = e.clientY - this.rect.top;
    // this.currarr.push({ x: this.x, y: this.y });
    this.point = this.getCursorPosition(this.canvas, e);
    this.currarr.push(this.point);
  }

  OnMouseUp(e: any) {
    if (this.isDrawing === true) {
      // var p = this.getCursorPosition(this.canvas, e);
      // //this.drawLine(this.ctx, this.x, this.y, e.clientX - this.rect.left, e.clientY - this.rect.top);
      // this.drawLine(this.ctx, this.point, p);
      // this.point = p;
      // this.currarr.push(p);
      this.isDrawing = false;
      if (!this.signarr)
        this.signarr = [];
      this.signarr.push(this.currarr);
      this.currarr = [];
    }
  }
  OnMouseMove(e: any) {
    if (this.isDrawing === true) {
      this.sigEmpty = false;
      // this.drawLine(this.ctx, this.x, this.y, e.clientX - this.rect.left, e.clientY - this.rect.top);
      // this.x = e.clientX - this.rect.left;
      // this.y = e.clientY - this.rect.top;

      // this.currarr.push({ x: this.x, y: this.y });
      var p = this.getCursorPosition(this.canvas, e);
      //this.drawLine(this.ctx, this.x, this.y, p.x, p.y);
      this.drawLine(this.ctx, this.point, p);
      this.point = p;
      // this.x = p.x;
      // this.y = p.y;
      this.currarr.push(p);
    }
  }
  getCursorPosition(canvas, event): pos {
    const rect = canvas.getBoundingClientRect()
    // const x = event.clientX - rect.left
    // const y = event.clientY - rect.top
    // const x = event.clientX - rect.left;
    // const y = event.clientY - rect.top;
    // const res = { x: x, y: y };

    const scaleX = canvas.width / rect.width;    // relationship bitmap vs. element for x
    const scaleY = canvas.height / rect.height;  // relationship bitmap vs. element for y

    const res = {
        x: (event.clientX - rect.left) * scaleX,   // scale mouse coordinates after they have
        y: (event.clientY - rect.top) * scaleY     // been adjusted to be relative to element
          };

    //console.log(res);

    return res;
  }


  public SigClear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.sigEmpty = true;
    this.signarr = [];
    this.currarr = [];

    //Only used for debug
    //this.makeGrid(this.ctx);
  }
  public GetSignatureLink() {
    return this.GetSignatureLinkImg();

    // var res = this.canvas.toDataURL('image/png', 0.1);
    // console.log(res);
    // //alert(res);
    // return res;
  }

  public GetSignatureLinkImg() {
    var fw: number = 255 / this.rect.width;
    var fh: number = 255 / this.rect.height;
    if (!this.signarr)
      return '';

    var res = this.signarr.map(sec => {
      return sec.map(it => {
        //Normalisera till 0-255
        var x: number = this.LimitValue(Math.round(it.x * fw), 0, 255);
        var y: number = this.LimitValue(Math.round(it.y * fh), 0, 255);
        return ('00' + x.toString(16)).substr(-2, 2) + ('00' + y.toString(16)).substr(-2, 2);
      }).join('');
    }).join('_');

    //Ratio: Height% of Weidth
    var ratio = this.GetHex(Math.round((this.rect.height / this.rect.width) * 100), 0, 255);

    console.log(res);

    return 'data:heximg;' + ratio + ':' + res;
  }
  private GetHex(val: number, min?: number, max?: number) {
    return ('00' + this.LimitValue(val, min, max).toString(16)).substr(-2, 2);
  }
  private LimitValue(val: number, min?: number, max?: number) {
    if (min && val < min)
      val = min;
    if (max && val > max)
      val = max;
    return val;
  }

  public EditSigName() {
    var res = prompt("Namnförtydligande:", this.sigName);
    if (res) this.sigName = res;
  }

  public drawLine(context, p1: pos, p2: pos) {
    context.beginPath();
    context.strokeStyle = 'black';
    context.lineWidth = 1.5;
    context.moveTo(p1.x, p1.y);
    context.lineTo(p2.x, p2.y);
    context.stroke();
    context.closePath();
  }

  public makeGrid(context) {
    const STEP_SIZE=10;
    var ch = context.canvas.height;
    var cw = context.canvas.width;
    for(var x=0;x < cw; x+=STEP_SIZE) {
      context.beginPath();
      context.strokeStyle = 'lightgray';
      context.lineWidth = (x % 50 == 0) ? 1 : 0.5;
      context.moveTo(x, 0);
      context.lineTo(x, ch);
      context.stroke();
      context.closePath();
    }
      for(var y=0;y < context.canvas.height; y+=STEP_SIZE) {
        context.beginPath();
      context.strokeStyle = 'lightgray';
      context.lineWidth = (y % 50 == 0) ? 1 : 0.5;
      context.moveTo(0, y);
      context.lineTo(cw, y);
      context.stroke();
      context.closePath();
    }
    
    
  }

}

class ColliScanStatus {
  ColliId: string;
  TspDocNo: string;
  Scanned: boolean;
}

class pos {
  public x: number;
  public y: number;
}
export interface ImageSpec {
  idName: string;
  URI: string;
}
//https://stackoverflow.com/questions/12869055/does-typescript-support-touchevent
interface HTMLElement extends
  Element
//MSHTMLElementRangeExtensions, 
//ElementCSSInlineStyle
//MSEventAttachmentTarget, 
//MSHTMLElementExtensions, 
//MSNodeExtensions 
{
  ontouchstart: (ev: TouchEvent) => any;
  ontouchmove: (ev: TouchEvent) => any;
  ontouchend: (ev: TouchEvent) => any;
  ontouchcancel: (ev: TouchEvent) => any;
}
