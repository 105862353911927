import { Component } from '@angular/core';
import { Navigation } from '@angular/router';
import { AlertController, AlertOptions, NavController } from '@ionic/angular';
import { ScanPage } from '../app/pages/scan/scan.page';
import { HomePage } from './pages/home/home/home.page';
import { PositionTrackerService } from './services/position-tracker/position-tracker.service';
import { UserService } from './services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
 
  pages: Array<{ title: string;  icon?: string, url: string }>;

  constructor(public alertCtrl:AlertController, public usr:UserService, public navCtrl:NavController,
    public posTrack:PositionTrackerService
    ) {
    this.pages = [
      { title: "Hem", url: 'home', icon: "home" },
      { title: "Order", url: 'list', icon: "cube" },
      // { title: 'Inbox', url: '/folder/Inbox', icon: 'mail' },
      // { title: 'Favorites', url: '/folder/Favorites', icon: 'heart' },
      { title: "Skanna", icon: "barcode", url: '/pages/scan' },
      // { title: "Lab", icon: "scale-outline", url: '/pages/lab' },
      {
        title: "Lastbärare",
        //component: BarCodeCollectionPage,
        url: 'bar-code-collection',
        icon: "basket"
      },
      // //{ title: 'Tidsrapport', component: TimereportPage, icon:'time' },
      { title: "Meddelanden", url: 'message', icon: "mail" }
      // { title: 'Logga ut', icon: 'log-out', url: '/pages/Logout'}
    ];

    this.posTrack.PositionTracker_Start();
  }

  public IsLoggedIn() {
    return this.usr?.IsLoggedIn();
  }
  
  async logout() {
    var opt:AlertOptions = { };
    
    let confirm = this.alertCtrl.create({
      header: "Logga ut?",
      message: "Vill du logga ut och avsluta?",
      buttons: [
        {
          text: "Avbryt",
          handler: () => {
            this.navCtrl.navigateRoot('home');            
          }
        },
        {
          text: "Logga ut",
          handler: () => {
            this.usr.Logout();

            //this.platform.exitApp();
            //window.location.reload();
            window.location.assign('/');
          }
        }
      ]
    });
    (await confirm).present();
  }
}
