
//
// {"EventType":"2","Place":"M","Comment":"","BarCodes":"00373500171447741051","RegisteredTime":"2017-11-08T12:34:33.986Z","GeoX":11.9834057,"GeoY":57.6485886,"Resource":"FKV_375","Attachments":[{"Description":"Bild","Link":"ID:1510144470794"}]}


export class MobileEvent {

    constructor() {
        this.Attachments = new Array<MobileEventAttachment>();
    }

    EventType: number;
    Place: string;
    Comment: string;
    BarCodes: string;
    RegisteredTime: string;
    GeoX: number;
    GeoY: number;
    Resource: string;
    TspDocNo?: string;
    JobItemId?: number;
    EurPallets?: number;
    EurPalletsReturn?: number;
    Attachments: Array<MobileEventAttachment> = [];    
    ColliMissingIds: Array<number> = [];
}

export class MobileEventAttachment {
    Description?: string;
    Link?: string;
    idName?: string;
    URI?: string;
}