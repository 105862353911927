import { Injectable } from '@angular/core';
import { SyncService } from 'src/app/services/sync/sync.service';
import { ApiService } from '../api/api.service';
import { SettingsService } from '../settings/settings.service';
import { UserService } from '../user/user.service';
import { interval } from 'rxjs';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { MobileEvent } from 'src/app/models/mobileevent';

@Injectable({
  providedIn: 'root'
})
export class PositionTrackerService {

  lastSync:Date;
  GeoX:number;
  GeoY:number;

  geo: Geolocation;

  constructor(
    private geoLoc: Geolocation,
    public api: ApiService,
    public usr: UserService,
    public sett: SettingsService,
    public sync: SyncService
  ) {
    this.geo = geoLoc;
    console.log('PositionTracker constr.');
  }

  PositionTracker_Start() {
    console.log('PositionTracker_Start');
    interval(1000 * this.sett.UpdatePositionIntervalSecs)
      // .takeWhile(() => true)
      .subscribe(i => {
        //console.log('Barcodecheck');
        this.TrackPosition();
      });
    }

    
      TrackPosition() {
        console.log('TrackPosition');
        this.geo.getCurrentPosition().then((resp) => {          
          if (resp.coords) {
            this.GeoX = resp.coords.longitude;
            this.GeoY = resp.coords.latitude;            
            if(this.usr._user) {
              if(this.usr._user.resource != '') {
                // this.api.UpdatePosition(this.GeoX, this.GeoY, this.usr._user.resource).subscribe( (res) => {
                //   //Actually do nothing;
                //   console.log('Updated position');
                //   this.lastSync = new Date();
                // })
                let event:MobileEvent = new MobileEvent();
                event.GeoX = this.GeoX;
                event.GeoY = this.GeoY;
                event.Resource = this.usr._user.resource;
                event.RegisteredTime = (new Date()).toISOString();
                var msg = JSON.stringify(event);
                this.sync.SaveEventToOutbox('POS', msg);
              }
            }
          }
        }).catch((error) => {
          console.log('Error getting location', error);
        });
      }
}
