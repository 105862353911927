export class User {
    username:string;
    sessionId:string = "";
    fullName:string;
    permissions: string[] = [];
    resource: string;    
    logoutTime: Date; //When the user is logged out automatically
    terminalId: string='10'; //Hard coded for GBS
    fcmToken: string; //To be used for Fcm
    
    /**
     * CheckPermission
     */
    public CheckPermission(permissionName:string) {
        if(this.permissions == null)
            return false;
        return (this.permissions.indexOf(permissionName.toUpperCase()))>=0;
    }
}   

