import { Injectable } from '@angular/core';
import { ApiService, LoginResponse } from "../api/api.service";
import { User } from "../../models/user";
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  _user: User = new User();
  Resources: string[];
  Settings: any = {};
  newVersionUrl: string;
  optionalVersionUrl:string;
  
  constructor(public api: ApiService) { }

  SaveState() {
    var ls = window.localStorage;
    ls.setItem("user", JSON.stringify(this._user));

    if (this.Settings) ls.setItem("settings", JSON.stringify(this.Settings));
  }
  UpdateFromStorage() {
    var ls = window.localStorage;
    var obj = ls.getItem("user");
    if (obj) {
      let usr: User = JSON.parse(obj);
      //TODO: Check loguttime
      this._user = usr;
      var sett = ls.getItem("settings");
      if (sett) {
        this.Settings = JSON.parse(sett);
      }
    }
  }

  public Logout() {
    this._user = new User();
    var ls = window.localStorage;
    ls.removeItem("user");
    ls.removeItem("settings");
  }

  public async Login2(usr: string, pwd: string): Promise<LoginResponse> {
    console.log('Entered login2');
    const r = await this.api.login(usr,pwd).toPromise();
    console.log('Returned from api.login');
    console.log(r);
    if (r.Error != null && r.Error !== '') {return r;}

      // if (r.NewVersionUrl && r.NewVersionUrl != "") {
      //   this.newVersionUrl = r.NewVersionUrl;
      //   return r;
      // }

      //Create user
      const u = new User();
      u.fullName = r.FullName;
      u.sessionId = r.SessionId;
      const d = new Date();
      d.setHours(24, 0, 0, 0);
      u.logoutTime = d;

      r.Permissions.map(x => u.permissions.push(x));
      this.Resources = [];
      if (r.ResourceList != null) {this.Resources = r.ResourceList.split('|');}

      if (r.Settings) {
        r.Settings.map(x => this.SetSetting(x.key, x.value));
      }
      // this._user.logoutTime = new Date();
      // this._user.logoutTime.setDate(this._user.logoutTime.getDate() + 1);

      this._user = u;
      console.log("Logged in:");
      console.log(this._user);

      this.SaveState();

      return r;
  }

  public Login(usr: string, pwd: string):any {
    // //Start - hard coded
    // if (usr == "a" && pwd == "b") {
    //   this._user = new User();
    //   this._user.fullName = "Anders Stensiö";
    //   this._user.permissions = ["MOBILE"];
    //   this._user.sessionId = "12345";
    //   this._user.logoutTime = new Date();
    //   this._user.logoutTime.setDate(this._user.logoutTime.getDate() + 0.5);
    // }

    var seq = this.api.login(usr, pwd);
    seq.forEach(r => {
      console.log(r);
      if (r.Error != null && r.Error != "") return r;

      if (r.NewVersionUrl && r.NewVersionUrl != "") {
        this.newVersionUrl = r.NewVersionUrl;
        return r;
      }

      //Create user
      var u = new User();
      u.fullName = r.FullName;
      u.sessionId = r.SessionId;
      var d = new Date();
      d.setHours(24, 0, 0, 0);
      u.logoutTime = d;

      r.Permissions.map(x => u.permissions.push(x));
      this.Resources = [];
      if (r.ResourceList != null) this.Resources = r.ResourceList.split("|");

      if (r.Settings) {
        r.Settings.map(x => this.SetSetting(x.key, x.value));
      }
      // this._user.logoutTime = new Date();
      // this._user.logoutTime.setDate(this._user.logoutTime.getDate() + 1);

      this._user = u;
      console.log("Logged in:");
      console.log(this._user);

      this.SaveState();

      return r;
    });

    return seq;
  }

  public GetFullName(): String {
    return this._user.fullName;
  }

  public IsLoggedIn(): Boolean {
    if (this._user == null || this._user == undefined) return false;

    //Must workaround TypeScript's bad typing
    var x = JSON.stringify(this._user.logoutTime);
    var nowDate = JSON.stringify(new Date());

    if (x < nowDate)
      return false;

    return this._user.sessionId != null && this._user.sessionId.length > 0;
    //return (this._user.logoutTime > (new Date())); //Check if it has timedout
  }

  public GetSetting(key: string, defaultValue?: string) {
    if (this.Settings && this.Settings[key]) return this.Settings[key];

    return defaultValue;
  }

  public SetSetting(key: string, value: string) {
    if (!this.Settings) this.Settings = {};
    //TODO: Kolla om värdet finns sedan tidigare!

    this.Settings[key] = value;
    console.log("[" + key + "]=" + this.GetSetting(key));
  }
}

export class UserSetting {
  public key: string;
  public value: string;

  /**
   *
   */
  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
  }
}
