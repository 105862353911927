import { Place } from "../models/place";
import { Colli } from "../models/colli";
import { BookingContact } from "../models/bookingcontact";

export class BookingStatus {
  id: number;
  prettyName: string;
}
export class BookingServiceType {
  id: number;
  prettyName: string;
}
export class Booking {
  //   JobId: number;
  //   JobItemId: number;
  //   LegType: string;
  //   TspDocNo: string;
  //   PickupPlace: Place;
  //   DeliveryPlace: Place;
  //   StatusId: number;
  //   Weight: number;
  //   Pallets: number;
  //   EurPallets: number;
  //   EurPalletsReturn: number;
  //   Comment: string;
  //   SortOrder: number;

  public id: number;
  public TspDocNo: string;
  public AlternativeTspDocNo: string;
  public OrderNo: string;
  public PickupPlace: Place;
  public DeliveryPlace: Place;
  public ServiceTypeId: number;
  public TransportService: string;
  public DeliveryDateText: string;
  public TourName: string;
  public StatusId: number;
  public Comment: string;
  public DeliveryReference: string;
  public DeliveryPhone: string;
  public NoOfItems: number;
  public Collis: Colli[];
  public Contacts: BookingContact[];
  public Events: BookingEvent[];

  public SortOrder: number;

  public IsPickup(): boolean {
    return this.ServiceTypeId == 20; //Hämtning
  }
  public IsDelivery(): boolean {
    return !this.IsPickup();
  }
  public IsPickedUp(): boolean {
    return this.StatusId == 300; //Lastad på bil
  }
  public IsDelivered(): boolean {
    return this.StatusId == 350;
  }
  public IsMissed(): boolean {
    return this.StatusId == 370;
  }
  public IsReturned(): Boolean {
    return this.StatusId == 380;
  }
  public IsMove(): Boolean {
    return this.ServiceTypeId == 70;
  }
  public IsTransport(): Boolean {
    return this.ServiceTypeId == 80;
  }
  
  public statuses(): BookingStatus[] {
    return [
      { id: 0, prettyName: "Ej påbörjad" },
      { id: 300, prettyName: "Lastad" },
      { id: 350, prettyName: "Levererad" },
      { id: 370, prettyName: "Bomkörning" },
      { id: 380, prettyName: "Återtagen" },
      { id: 410, prettyName: "Utredning" }
    ];
  }

  public StatusName(): string {
    var s = this.StatusId;
    var res = this.statuses().find(it => {
      return it.id == s;
    });
    if (res) return res.prettyName;

    return "Status (" + s + ")";
  }
  public serviceTypes(): BookingServiceType[] {
    return [
      { id: 0, prettyName: "(Okänd)" },
      { id: 10, prettyName: "Leverans" },
      { id: 20, prettyName: "Hämtning" },
      { id: 30, prettyName: "Verkstad" },
      { id: 40, prettyName: "Manuell" },
      { id: 50, prettyName: "Akut" },
      { id: 70, prettyName: "Flytt" },
      { id: 80, prettyName: "Transport" },
      
    ];
  }
  public ServiceTypeName(): string {
    var s = this.ServiceTypeId;
    var res = this.serviceTypes().find(it => {
      return it.id == s;
    });
    if (res) return res.prettyName;

    return "Typ: (" + s + ")";
  }
  public GetGoodsAmount() {
    return `${this.NoOfItems} kolli`; //`${this.Weight}kg, ${this.Pallets}pall`;
  }


  public GetContact(type: String) {
    if (this.Contacts) {
      var res = this.Contacts.find(x => {
        return x.Type == type;
      });
      if (res) return res;
    }

    return new BookingContact();
  }
}

export interface IBooking {
  JobId: number;
  JobItemId: number;
  LegType: string;
  TspDocNo: string;
  PickupPlace: Place;
  DeliveryPlace: Place;
  StatusId: number;
  Weight: number;
  Pallets: number;
  EurPallets: number;
  EurPalletsReturn: number;
  Comment: string;
}

export class BookingEvent {
  public time: string;
  public eventtypeid: number;
  public comment: string;
}
