import { Injectable } from '@angular/core';
import { Booking, BookingEvent } from "../../models/booking";
import { Place } from "../../models/place";
import { ApiService } from '../api/api.service';
import { UserService } from '../user/user.service';
import { MobileEvent } from "../../models/mobileevent";

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  bookings: Array<Booking>;

  //TODO: Move this to user settings or something???
  resource: string;
  sender: number;

  public eventTypes: BookingEventType[] = [
    {
      key: 'PICKUP', name: 'Hämtning', eventTypeId: 1, description: "Hämtning hos avsändare", place: "A", newStatusId: 300, mustScanTspDocNo: false,
      IsAllowedForBooking: (booking) => (booking.IsPickup() || booking.IsMove() || booking.IsTransport()) && !booking.IsPickedUp() && !booking.IsDelivered(),
      MustScanTspDocNo: (booking) => false,
      OnAfterEvent: (b) => null,
    },
    {
      key: 'LOAD',
      name: 'Lastning', eventTypeId: 1, description: "Lastning på terminal", place: "", newStatusId: 300, mustScanTspDocNo: false,
      IsAllowedForBooking: (booking) => !(booking.IsMove() || booking.IsTransport()) && !booking.IsPickup() && !booking.IsPickedUp() && booking.IsDelivery() && !booking.IsDelivered(),
      MustScanTspDocNo: (booking) => true, // !(booking.IsMove() || booking.IsTransport()),
      OnAfterEvent: (b) => null,
    },
    {
      key: 'DELIVERY',
      name: 'Leverans', eventTypeId: 2, newStatusId: 350, description: "Leverans hos mottagare", place: "M", mustScanTspDocNo: true,
      IsAllowedForBooking: (booking) => booking.IsDelivery() && !booking.IsDelivered(),
      MustScanTspDocNo: (booking) => !(booking.ServiceTypeId == 20 || booking.ServiceTypeId == 30 || booking.ServiceTypeId == 40 || booking.IsMove() || booking.IsTransport()),
      OnAfterEvent: (b) => null,
    },
    {
      key: 'MISSED',
      name: 'Bomkörning', eventTypeId: 25, newStatusId: 370, description: "Bomkörning vid leverans", place: "", mustScanTspDocNo: false,
      IsAllowedForBooking: (booking) => ((booking.IsPickedUp() && booking.IsDelivery()) || (booking.IsPickup() && !booking.IsPickedUp())) && !booking.IsDelivered() && !booking.IsMissed(),
      MustScanTspDocNo: (booking) => false,
      OnAfterEvent: (b) => null,
    },
    {
      key: 'RETURNED',
      name: 'Åter terminal', eventTypeId: 27, newStatusId: 380, description: "Åter terminal", place: "", mustScanTspDocNo: false,
      IsAllowedForBooking: (booking) => booking.IsMissed() && !booking.IsDelivered(),
      MustScanTspDocNo: (booking) => false,
      OnAfterEvent: (b) => null,
    },
    {
      key: 'COMMENT',
      name: 'Notering', eventTypeId: 70, newStatusId: null, description: "Notering, påverkar inte status", place: "", mustScanTspDocNo: false,
      IsAllowedForBooking: (booking) => true,
      MustScanTspDocNo: (booking) => false,
      OnAfterEvent: (booking, e) => {
        booking.Events = (!booking.Events) ? [] : booking.Events;
        var ev = new BookingEvent();
        ev.comment = e.Comment;
        booking.Events.push(ev);
      }
    }

  ]
  constructor(public api: ApiService, public usr: UserService) {
    //Tog bort dependcies till Http. Fixa det senare
    this.bookings = new Array<Booking>();
    //Mock
    let b: Booking;

    this.InitDateFilters();
    console.log("Startdata Bookings:");
    console.log(this.bookings);
  }

  RefreshBookingData() {
    console.log("GetBookingData");

    var resource = this.usr._user.resource;
    var sessionId = this.usr._user.sessionId;
    this.CheckDateFilters();

    var res = this.api.getBookingData(resource, sessionId);
    console.log(res);
    res.forEach(r => {
      console.log(r);
      var newBs = Array<Booking>();

      var index = 0;
      for (var i = 0; i < r.length; i++) {
        let b: Booking = Object.assign(new Booking(), r[i]);
        if (b && b.id > 0) {
          b.SortOrder = ++index;
          newBs.push(b);
        }
      }

      this.bookings = newBs;
      console.log("Ny data Bookings:");
      console.log(this.bookings);
    });
  }

  public CheckDateFilters(): void {
    if (!this.DateFilters || this.DateFilters[0].dateText != this.GetDateText(new Date()))
      this.InitDateFilters();
  }

  private InitDateFilters(): void {
    this.DateFilters = [];
    var d = new Date();
    this.DateFilters.push({ name: "Idag", dateText: this.GetDateText(d), color: "primary" })
    for (var i = 0; i < 6; i++) {
      d.setDate(d.getDate() + 1);
      this.DateFilters.push({ name: this.GetWeekDayText(d) + ' ' + this.GetDateText(d), dateText: this.GetDateText(d), color: "danger" });
    }
    this.currentDateFilter = this.DateFilters[0];
  }

  public GetEventTypesForBookings(bs: Booking[]): BookingEventType[] {
    var res: BookingEventType[] = [];

    this.eventTypes.forEach((et) => {
      if (!(bs.find((b) => !et.IsAllowedForBooking(b))))
        res.push(et);
    });
    return res;
  }

  public GetEventTypeByKey(key: string): BookingEventType {
    return this.eventTypes.find((x) => x.key == key);
  }
  public GetBookingCountForDate(dateFilter: DateFilter): Number {
    if (!this.bookings)
      return 0;
    return this.bookings.filter(it => it.DeliveryDateText == dateFilter.dateText).length;
  }


  GetBookings(filter): Booking[] {
    let res: Booking[] = this.bookings;

    switch (filter) {
      case "ALL":
        res = this.bookings;
        break;
      case "PICKEDUP":
        res = res.filter(it => it.StatusId == 300);
        break;
      case "DELIVERED":
        res = res.filter(it => it.StatusId == 350);
        break;
      case "NONE":
        res = res.filter(it => it.StatusId < 150);
        break;
    }

    res = res.filter(it => it.DeliveryDateText == this.currentDateFilter.dateText);
    res = res.sort(function (a, b) {
      return a.SortOrder - b.SortOrder;
    });

    return res;
  }

  GetBookingWithJobId(Id: number) {
    return this.bookings.find(x => x.id == Id);
  }
  SetStatus(id: number, newStatusId: number) {
    if (newStatusId) {
      var idx = this.bookings.findIndex(x => x.id == id);
      if (idx >= 0) {
        this.bookings[idx].StatusId = newStatusId;
      }
    }
  }

  //bc's should already be expanded in bar-code-collection
  public CheckBookingsFromBarcodeBeforeEvent(bc: string[], et: BookingEventType): BookingValidationResult[] {
    var bs = this.bookings.filter((x) => bc.findIndex((c) => c == x.TspDocNo) >= 0);

    var res = this.CheckBookingsBeforeEvent(bs, et);
    bc.filter((x) => this.GetBookings('ALL').findIndex(y => y.TspDocNo == x) < 0)
      .map((code) => res.unshift(new BookingValidationResult(code + ' kunde inte hittas.', true)));

    return res;
  }

  public CheckBookingsBeforeEvent(bookings: Booking[], et: BookingEventType): BookingValidationResult[] {
    var res: BookingValidationResult[] = [];

    // var et = this.eventTypes.find((x) => x.eventTypeId == eventTypeId);
    // if (!et) {
    //   res.push(new BookingValidationResult('Okänd händelsetyp. Vänligen försök igen.', true));
    //   return res;
    // }

    //Check if possible
    var notAllowed = bookings.filter((b) => !et.IsAllowedForBooking(b));
    if (notAllowed.length > 0) {
      res.push(new BookingValidationResult(notAllowed.map((x) => x.TspDocNo).join(',') + ' kan ej hantera händelsen ' + et.name, true));
      return res;
    }

    switch (et.eventTypeId) {
      case 1: //Pickup
        //Kontrollera att de inte är levererade
        var alreadyPickedup = bookings.filter((x) => x.IsPickedUp());
        if (alreadyPickedup.length > 0)
          res.push(new BookingValidationResult(alreadyPickedup.map((x) => x.TspDocNo).join(',') + '  är redan lastad(e).', false));

        //Kontrollera om det finns fler med samma gata+postnr+ort
        var puAdresses = Array.from(new Set(bookings.map((x) => this.GetAddressString(x)))); //Unique
        if (puAdresses.length > 1)
          res.push(new BookingValidationResult('Du har kombinerat flera olika adresser:\n' + puAdresses.join('\n'), false));

        var notSelectedBookings = this.GetBookings('ALL').filter((x) => bookings.findIndex((y) => y == x) < 0);
        puAdresses.forEach((x) => {
          var bookingsNotSelectedButWithSameAddress = notSelectedBookings.filter((y) => x == this.GetAddressString(y) && y.IsPickup() && !y.IsPickedUp());
          if (bookingsNotSelectedButWithSameAddress.length > 0)
            res.push(new BookingValidationResult('Följesedel ' + bookingsNotSelectedButWithSameAddress.map((o) => o.TspDocNo).join(',') + ' ska hämtas på ' + x + ' men är inte i urval\n', false));
        });
        break;

      case 2: //Delivery

        //Kontrollera att de inte är levererade eller pickedup
        var alreadyDelivered = bookings.filter((x) => x.StatusId == 350);
        if (alreadyDelivered.length > 0)
          res.push(new BookingValidationResult(alreadyDelivered.map((x) => x.TspDocNo).join(',') + '  är redan levererad(e).', false));

        //Kontrollera om det finns fler med samma gata+postnr+ort
        var delAdresses = Array.from(new Set(bookings.map((x) => this.GetAddressString(x)))); //Unique
        if (delAdresses.length > 1)
          res.push(new BookingValidationResult('Du har kombinerat flera olika adresser:\n' + delAdresses.join('\n'), false));

        var notSelectedBookings = this.GetBookings('ALL').filter((x) => et.IsAllowedForBooking(x) && bookings.findIndex((y) => y == x) < 0);
        delAdresses.forEach((x) => {
          var bookingsNotSelectedButWithSameAddress = notSelectedBookings.filter((y) => x == this.GetAddressString(y));
          if (bookingsNotSelectedButWithSameAddress.length > 0)
            res.push(new BookingValidationResult('Följesedel ' + bookingsNotSelectedButWithSameAddress.map((o) => o.TspDocNo).join(',') + ' ska till ' + x + ' men är inte i urval\n', false));
        });
        break;
    }


    return res;
  }
  GetPlace(b: Booking): Place {
    return b.IsPickup() ? b.PickupPlace : b.DeliveryPlace;
  }

  private GetAddressString(x: Booking): string {
    var p = this.GetPlace(x);
    return (p.Street + ',' + p.PostCode + ' ' + p.City).toUpperCase();
  }

  public currentDateFilter: DateFilter;
  public DateFilters: Array<DateFilter>;

  private GetDateText(date: Date): string {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
      .toISOString()
      .split("T")[0];
  }
  private GetWeekDayText(date: Date): string {
    const weekday = "Sön,Mån,Tis,Ons,Tor,Fre,Lör,Sön".split(',');
    return weekday[date.getDay()];
  }
}

export class BookingEventType {
  key: string;
  eventTypeId: number;
  name: string;
  description: string;
  newStatusId: number;
  place: string;
  mustScanTspDocNo?: boolean;
  public IsAllowedForBooking(b: Booking): boolean {
    return false; //To be replaced
  };
  public MustScanTspDocNo(b: Booking): boolean {
    return true;
  }
  public OnAfterEvent(b: Booking, e: MobileEvent) { }
}

export class BookingValidationResult {
  constructor(message: string, isServere: boolean) {
    this.isSevere = isServere;
    this.message = message;
  }

  isSevere: boolean;
  message: string;
}

export class DateFilter {
  public name: string;
  public dateText: string;
  public color: string;
}
