import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class SyncService {


  constructor(public api: ApiService, public usr: UserService) {
    this.synclog = [];
    this.AddToLog('Component created');
  }

  noOfMessagesInQueue: number; //Start as undefined for QueueLength
  autoSynchIntervalSecs = 30;
  autoSynchEnabled = true;
  syncTimeoutSecs = 60;
  keyName = 'Messages';

  public synclog: string[] = [];

  //https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  private generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public GetNewID() {
    return this.generateUUID().replace('-', '');
  }

  SaveEventToOutbox(command, msg) {
    console.log('SaveEventToOutbox');
    var id = this.GetNewID();
    let objToSave: QueueEvent = { command: command, msg: msg, date: new Date(), id: id };
    this.SaveToOutBox(objToSave);
  }

  SaveAttachmentToOutBox(id, link) {
    let objToSave: QueueEvent = { id: id, link: link, date: new Date() };
    this.SaveToOutBox(objToSave);
  }
  SaveToOutBox(objToSave: QueueEvent) {
    var lst = this.GetList();
    lst.push(objToSave);
    this.SetList(lst);
  }

  QueueLength() {
    if (this.noOfMessagesInQueue == undefined) {
      this.noOfMessagesInQueue = this.GetList().length;
    }
    return this.noOfMessagesInQueue;
  }

  GetList(): Array<QueueEvent> {
    var res = [];

    var ls = window.localStorage;
    var obj = ls.getItem(this.keyName);
    if (obj != null && obj != undefined)
      res = JSON.parse(obj);

    this.noOfMessagesInQueue = res.length;

    return res;
  }

  SetList(list) {
    var ls = window.localStorage;
    ls.setItem(this.keyName, JSON.stringify(list));
    this.noOfMessagesInQueue = list.length;
  }

  SyncFirstMessage() {
    this.AddToLog('Sync check:' + this.QueueLength());
    if (this.QueueLength() > 0) {
      var lst = this.GetList();
      var o = lst[0];
      if (o.inProcess != undefined && o.inProcess != null) {
        if (o.processStarted != null && o.processStarted != undefined) {
          var d = new Date(o.processStarted);
          console.log(d);

          var now = new Date();
          if ((now.getTime() - d.getTime()) < this.syncTimeoutSecs * 1000) {
            //Wait a bit more...
            return;
          }
        }
      }
      //Start sync process
      this.AddToLog('Sync started for:' + o.id + '-' + o.command);
      o.inProcess = true;
      o.processStarted = new Date();
      lst[0] = o;
      this.SetList(lst);

      this.Transfer(o);

    }
  }
  RemoveFirstMessage() {
    var lst = this.GetList(); //Funkar this i detta context?
    var o = lst[0];
    if (o) {
      this.removeEntry(o.link);
      lst.shift(); //Remove first object
      this.SetList(lst);
      //this.RefreshSynchStatus();  
    }
  }

  // TransferWithId(id) {
  //   var lst = this.GetList(); //Funkar this i detta context?
  //   for (var i = 0; i < lst.length; i++)
  //     if (lst[i].id == id)
  //       this.Transfer(lst[i].link);
  // }

  Transfer(o: QueueEvent) {
    var sender = this.usr._user.sessionId;

    if (o.link) {
      //File transfer
      console.log('This is file transfer');
      this.AddToLog('Before file transfer');
      this.api.TransferFile(o.link).then(
        (entry) => {
          this.RemoveFirstMessage();
          console.log('upload complete. ');
          this.AddToLog('upload complete. ');
        },
        (error) => {
          //Do nothing
          console.log('File transfer failed:');
          console.log(error);
          this.AddToLog('File transfer failed');
          this.AddToLog(JSON.stringify(error));
        }
      )
    } else {
      this.AddToLog('Before sendEvent...');
      this.api.sendEvent(sender, o.id, o.command, o.msg)
        .subscribe(r => {
          //Synch_SetInProcess(false);
          if (r == "OK:") {
            this.RemoveFirstMessage();
            this.AddToLog('Sync OK!');
          } else {
            console.log('Sync failed. Response:');
            console.log(r);
            this.AddToLog('Sync failed. Response:');
            this.AddToLog(JSON.stringify(r));
          }
        })
    }

  }


  /* Cleanup */
  removeEntry(imgUri) {
    //Behövs detta i Queue?
  }

  AutoSynch() {
    //BLIR STACK OVERFLOW???
    console.log('Autosync');
    if (this.autoSynchEnabled)
      setTimeout(this.SyncFirstMessage, 100);

    setTimeout(this.AutoSynch, this.autoSynchIntervalSecs * 1000);
  }

  AutoSync_Starter() {
    console.log('AutoSync_Starter');
    interval(10000)
      .pipe(takeWhile(() => true))
      .subscribe(i => {
        console.log('Observable-synch');
        this.SyncFirstMessage();

        //Check for outlog
        if (!this.usr.IsLoggedIn()) {
          this.usr.Logout();
          window.location.reload();
        }

      })
  }

  AddToLog(s: string) {
    var t = new Date();
    var dt = t.getHours() + ':' + t.getMinutes() + ':' + t.getSeconds()
    this.synclog.push('(' + dt + ')' + s);
  }

}

interface QueueEvent {
  inProcess?: Boolean;
  processStarted?: Date;
  command?: string;
  msg?: string;
  date: Date;
  id: string;
  link?: string;
}